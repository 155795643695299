import React from "react";
import { Helmet } from "react-helmet";

import { useFooterQuery } from "@api";
import { SanitySeo } from "@graphql-types";

interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string;
  overwriteImage?: string;
  overwriteDescription?: string;
  slug?: string;
}

const SEO = (props: Props) => {
  const { sanitySiteConfig } = useFooterQuery();

  const urlString = props.slug
    ? `${process.env.GATSBY_BASE_URL}/${props.slug}`
    : process.env.GATSBY_BASE_URL;

  return (
    <Helmet
      title={
        props.overwriteTitle ||
        props.seoData?.pageTitle ||
        (sanitySiteConfig?.defaultSEO?.pageTitle as string)
      }
    >
      <meta
        name="description"
        content={
          props.seoData?.pageDescription ||
          props.overwriteDescription ||
          (sanitySiteConfig?.defaultSEO?.pageDescription as string)
        }
      />
      <meta
        name="keywords"
        content={
          props.seoData?.pageKeyWords || (sanitySiteConfig?.defaultSEO?.pageKeyWords as string)
        }
      />
      <html lang="en" />

      <meta
        property="og:title"
        content={
          props.overwriteTitle ||
          props.seoData?.pageTitle ||
          (sanitySiteConfig?.defaultSEO?.pageTitle as string)
        }
      />
      <meta property="og:type" content={props.post ? `article` : `website`} />
      <meta property="og:url" content={urlString} />
      <meta
        property="og:image"
        content={
          props.seoData?.ogImage?.asset?.url ||
          props.overwriteImage ||
          (sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url as string)
        }
      />
      <meta
        property="og:description"
        content={
          props.seoData?.pageDescription ||
          props.overwriteDescription ||
          (sanitySiteConfig?.defaultSEO?.pageDescription as string)
        }
      />

      {/* Twitter Card tags */}
      <meta
        name="twitter:card"
        content={
          props.seoData?.pageDescription ||
          props.overwriteDescription ||
          (sanitySiteConfig?.defaultSEO?.pageDescription as string)
        }
      />
      <meta
        name="twitter:title"
        content={
          props.overwriteTitle ||
          props.seoData?.pageTitle ||
          (sanitySiteConfig?.defaultSEO?.pageTitle as string)
        }
      />
      <meta
        name="twitter:image"
        content={
          props.seoData?.ogImage?.asset?.url ||
          props.overwriteImage ||
          (sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url as string)
        }
      />
      <meta property="twitter:site" content={urlString} />
      <meta
        name="twitter:description"
        content={
          props.seoData?.pageDescription ||
          props.overwriteDescription ||
          (sanitySiteConfig?.defaultSEO?.pageDescription as string)
        }
      />
      {props.seoData?.jsonLD && (
        <script type="application/ld+json">{props.seoData.jsonLD.code}</script>
      )}
    </Helmet>
  );
};

export default SEO;
