import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import queryString from "query-string";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PackageType, PageContext } from "@util/types";
import { isBrowser } from "@util/helper";
import { tierArr } from "@util/constants";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const handleTierOnload = () => {
  if (!isBrowser()) return "";

  const parsedSearch = queryString.parse(window.location.search);

  if (parsedSearch && parsedSearch.tier) {
    return parsedSearch.tier as string;
  }

  return tierArr[0];
};

export default function PackageTemplate({ data, pageContext }: Props) {
  if (!data.sanityPackageLanding) return null;

  const [currentTier, setCurrentTier] = useState(handleTierOnload());
  const { pageMeta, tiers } = data.sanityPackageLanding;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />

      {/* {tiers &&
        tiers.map(tier => {
          if (!tier || tier.tierList !== currentTier) return;

          return (
            <PackageCustomiser
              key={`customiser-${tier}`}
              tierDetails={tier}
              setCurrentTier={setCurrentTier}
              type={pageContext.type as PackageType}
              currentTier={currentTier}
            />
          );
        })} */}
    </>
  );
}

export const query = graphql`
  query PackageCustomiseQuery($slug: String) {
    sanityPackageLanding(pageMeta: { slug: { current: { eq: $slug } } }) {
      pageMeta {
        ...sanityPageMeta
      }
      tiers {
        ...sanityTier
        customisableProductsDefault {
          product {
            ...sanityProduct
          }
          defaultSku
          defaultQuantity
        }
        customisableProductsAddOn {
          ...sanityProduct
        }
      }
    }
  }
`;
